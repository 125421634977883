<script>
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import { mapState } from "vuex";

export default {
  name: "Slider",
  components: { MainButtonIcon },

  data() {
    return {
      images: [
        // {
        //   id: 1,
        //   imageDesktop: require("../../assets/images/dashboard-slider/banner1.png"),
        //   imageMobile: require("../../assets/images/dashboard-slider/banner1_mobile.png"),
        // },

        {
          id: 2,
          imageDesktop: require("../../assets/images/dashboard-slider/list_distribution_offices.png"),
          imageMobile: require("../../assets/images/dashboard-slider/list_distribution_offices_mobile.png"),
          link: "https://disk.yandex.ru/d/v7FIwC-Ns6_h6Q",
        },

        {
          id: 3,
          imageDesktop: require("../../assets/images/dashboard-slider/business_forums_september.png"),
          imageMobile: require("../../assets/images/dashboard-slider/business_forums_september_mobile.png"),
          link: "https://disk.yandex.ru/d/k_F8lq3_nmF3pQ",
        },

        {
          id: 4,
          imageDesktop: require("../../assets/images/dashboard-slider/business_breakfasts_september.png"),
          imageMobile: require("../../assets/images/dashboard-slider/business_breakfasts_september_mobile.png"),
          link: "https://disk.yandex.ru/d/k_F8lq3_nmF3pQ",
        },

        {
          id: 5,
          imageDesktop: require("../../assets/images/dashboard-slider/new_banner2.png"),
          imageMobile: require("../../assets/images/dashboard-slider/new_banner2_mobile.png"),
          link: { name: "RC House" },
        },
      ],

      activeImage: null,
      wrapperScrollWidth: 0,
      imageWidth: 0,
      x: 0,
      wrapperOffsetWidth: 0,
      interval: null,
    };
  },

  watch: {
    innerWidth() {
      this.createVariables();

      this.sliderScroll(0);
    },

    activeImage: {
      deep: true,
      handler() {
        clearInterval(this.interval);
        this.timer();
      },
    },
  },

  computed: {
    ...mapState({
      innerWidth: (state) => state.innerWidth,
    }),

    wrapperWidth() {
      this.$nextTick(() => {
        return `max-width: ${this.imageWidth}px`;
      });

      return "";
    },
  },

  methods: {
    timer() {
      this.interval = setInterval(() => {
        this.nextSlide();
      }, 10000);
    },

    isActiveStep(image) {
      return image === this.activeImage;
    },

    changeImage(image) {
      let oldIndex = this.images.findIndex((item) => item === this.activeImage);
      let newIndex = this.images.findIndex((item) => item === image);

      if (newIndex > oldIndex) {
        this.nextSlide(newIndex - oldIndex);
        this.activeImage = image;
      } else if (newIndex < oldIndex) {
        this.prevSlide(oldIndex - newIndex);
        this.activeImage = image;
      }
    },

    swipeImage(val) {
      if (val === "left") {
        this.nextSlide();
      } else {
        this.prevSlide();
      }
    },

    nextSlide(index) {
      let coefficient = index ?? 1;

      if (this.x + this.imageWidth * coefficient >= this.wrapperScrollWidth) {
        this.x = 0;
        this.activeImage = this.images[0];
      } else {
        this.x += this.imageWidth * coefficient;
        this.activeImage = this.images[
          this.images.findIndex((item) => item === this.activeImage) + 1
        ];
      }

      this.sliderScroll(this.x);
    },

    prevSlide(index) {
      let coefficient = index ?? 1;

      if (this.x - this.imageWidth * coefficient < 0) {
        this.x = this.wrapperScrollWidth - this.imageWidth * coefficient;
        this.activeImage = this.images[this.images.length - 1];
      } else {
        this.x -= this.imageWidth * coefficient;
        this.activeImage = this.images[
          this.images.findIndex((item) => item === this.activeImage) - 1
        ];
      }

      this.sliderScroll(this.x);
    },

    sliderScroll(x) {
      document
        .getElementsByClassName("slider__wrapper")[0]
        .scroll({ top: 0, left: x, behavior: "smooth" });
    },

    createVariables() {
      this.$nextTick(() => {
        this.wrapperScrollWidth = document.getElementsByClassName(
          "slider__wrapper"
        )[0].scrollWidth;
        this.imageWidth = document.getElementsByClassName(
          "slider__image"
        )[0].offsetWidth;

        this.wrapperOffsetWidth = document.getElementsByClassName(
          "slider__wrapper"
        )[0].offsetWidth;
      });
    },

    useImage(slide) {
      return this.innerWidth > 576 ? slide.imageDesktop : slide.imageMobile;
    },

    redirectToExternal(slide) {
      if (typeof slide.link === "string") {
        window.open(slide.link, "_blank");
      } else {
        const path = this.$router.resolve(slide.link).href;

        // Открываем ссылку в новой вкладке
        window.open(path, "_blank");
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.createVariables();
    }, 500);
    this.activeImage = this.images[0];
    if (this.images.length > 1) {
      this.timer();
    }
  },
};
</script>

<template>
  <section class="slider">
    <div
      class="slider__content"
      :class="{ 'default-content-padding': innerWidth > 575 }"
    >
      <MainButtonIcon
        v-if="images.length > 1"
        class="slider__navigation-btn"
        @click="prevSlide"
      >
        <span class="icon-arrow" />
      </MainButtonIcon>

      <MainButtonIcon
        v-if="images.length > 1"
        class="slider__navigation-btn"
        @click="nextSlide"
      >
        <span class="icon-arrow" />
      </MainButtonIcon>

      <div class="slider__wrapper" :style="wrapperWidth">
        <router-link
          v-for="image in images"
          :key="image.id"
          :to="{}"
          target="_blank"
          @click.native.prevent="() => redirectToExternal(image)"
        >
          <img
            v-touch:swipe.left="() => swipeImage('left')"
            v-touch:swipe.right="() => swipeImage('right')"
            class="slider__image"
            :src="useImage(image)"
            alt="banner"
          />
        </router-link>
      </div>
    </div>

    <div
      v-if="images.length > 1"
      class="slider__navigation-block navigation-block"
    >
      <MainButtonIcon
        v-for="(image, index) in images"
        :key="index"
        class="navigation-block__btn"
        @click="changeImage(image)"
      >
        <span
          class="navigation-block__step"
          :class="{ 'navigation-block__step_active': isActiveStep(image) }"
        />
      </MainButtonIcon>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.slider {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  max-width: 100vw;

  a {
    display: contents;
  }

  img {
    min-height: 220px;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    transition: all;
  }

  &__content {
    position: relative;
  }

  &__wrapper {
    display: flex;
    overflow: hidden;
    z-index: 1;
    transition: all 2s ease-out;
  }
}

.slider__navigation-btn {
  display: none;
}

.navigation-block {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 16px;

  &__btn {
    width: 100%;
    padding: 4px;
    position: relative;
  }

  &__step {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 8px;
    background: rgb(207, 207, 207);
    position: relative;
    z-index: 1;

    &:hover {
      background: rgb(125, 125, 125);
    }

    &_active {
      &::after {
        left: 0;
        opacity: 1 !important;
        position: absolute;
        content: " ";
        width: 100%;
        height: 3px;
        border-radius: 8px;
        background: $dark-primary;
        animation: animationProgress 10s ease;
        z-index: 2;
      }
    }
  }
}

@keyframes animationProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@media (min-width: 375px) {
  .slider img {
    min-height: 250px;
  }

  .navigation-block {
    padding: 0 24px;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .slider img {
    min-height: 280px;
  }

  .navigation-block {
    padding: 0 32px;
  }
}

@media (min-width: 576px) {
  .slider {
    img {
      border-radius: 8px;
      height: auto;
    }
  }

  .slider__navigation-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: $light-primary;
    box-shadow: 0 0 25px 0 rgba(33, 33, 33, 0.1);
    z-index: 2;

    .icon-arrow {
      width: 24px;
      height: 24px;
      background: $dark-fifth;
    }

    &:hover {
      .icon-arrow {
        background: $dark-primary;
      }
    }

    &:nth-child(1) {
      left: 32px;

      .icon-arrow {
        transform: rotate(-90deg);
      }
    }

    &:nth-child(2) {
      right: 32px;

      .icon-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .navigation-block {
    padding: 0;
    margin: 0 auto;

    &__step {
      width: 56px;
    }
  }
}

@media (min-width: 900px) {
  .slider {
    margin-bottom: 32px;
  }
}

@media (min-width: 1024px) {
  .slider {
    margin-bottom: 64px;
  }
}

@media (min-width: 1360px) {
  .slider {
    max-width: 940px;
  }
}
</style>
